<script setup>
import { ref, onMounted, watch } from "vue";
import urls from "../common/urls.js";
import { uploadFile } from "../api/planet.js";
import { list, edit, getTypes } from "../api/redPacket.js";
import { message } from "ant-design-vue";

const qiniuUrl = urls.qiniuBase;

const PAGE_SIZE = 20;
const NAME_MAP = {
  0: "淘宝超级红包",
  1: "京东超级红包",
  2: "美团红包",
  3: "饿了么红包",
  4: "自定义小程序",
  5: "拼多多红包",
  6: "内部地址",
  7: "口令",
};
const TYPES = [
  { label: "淘宝超级红包", value: "0" },
  { label: "京东超级红包", value: "1" },
  { label: "美团红包", value: "2" },
  { label: "饿了么红包", value: "3" },
  { label: "自定义小程序", value: "4" },
  { label: "拼多多红包", value: "5" },
  { label: "内部地址", value: "6" },
  { label: "口令", value: "7" },
];
const H5_TYPES = [
  { label: "链接", value: "0" },
  { label: "图片", value: "1" },
];

const pageNum = ref(1);
const pageSize = ref(PAGE_SIZE);

const columns = [
  { title: "ID", dataIndex: "logicId" },
  { title: "名称", dataIndex: "type", slots: { customRender: "type" } },
  { title: "标题", dataIndex: "title" },
  { title: "状态", dataIndex: "status", slots: { customRender: "status" } },
  { title: "操作", dataIndex: "op", slots: { customRender: "op" } },
];
const source = ref([]);
const loading = ref(false);
const pagination = ref({
  position: "bottom",
  total: 0, // 条数
  defaultPageSize: PAGE_SIZE, // 默认分页条数
  pageSizeOptions: ["5", "10", "15", "20", "30"],
  showSizeChanger: true, // 是否可以改变 pageSize
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: (total) => `共 ${total} 条`,
  hideOnSinglePage: false, // 只有一页时是否隐藏分页器
  onChange: (selectedRowKeys, selectedRows) => {
    loading.value = true;
    const params = { pageNum: selectedRowKeys, pageSize: selectedRows }
    if (categoryId.value !== '0') {
      params.categoryId = categoryId.value
    }
    getList(params);
  },
});

const handleAdd = (obj) => {
  if (obj) {
    modalTitle.value = "编辑红包";
    const {
      type,
      title,
      description,
      url,
      img,
      appletId,
      logicId,
      status,
      tpwd,
      category,
      sort,
      h5Type,
      h5Url
    } = obj;
    formModal.value.data = {
      type: type.toString(),
      title,
      description,
      url: url ? decodeURIComponent(url) : tpwd,
      img,
      appletId,
      logicId,
      status: String(status),
      category,
      sort,
      h5Type,
      h5Url: h5Url ? decodeURIComponent(h5Url) : ''
    };
    if (img) {
      const key = img.split("/").pop();
      fileList.value = [
        {
          name: key || "",
          response: { key: key || "" },
          status: "done",
          url: img,
          uid: "-1",
        },
      ];
    }
  } else {
    modalTitle.value = "新增红包";
    formModal.value.data = {
      type: null,
      title: "",
      description: "",
      url: "",
      img: "",
      appletId: "",
      status: "0",
      category: categoryId.value === '0' ? null : categoryId.value,
      sort: 1,
      h5Type: '0',
      h5Url: ''
    };
  }
  modalVisible.value = true;
};
const getList = async (params) => {
  pageNum.value = params.pageNum;
  pageSize.value = params.pageSize;
  const { data } = await list(params);
  const { records, total } = data;
  source.value = records;
  pagination.value.total = total;
  loading.value = false;
};
const refresh = () => {
  const params = { pageNum: pageNum.value, pageSize: pageSize.value }
  if (categoryId.value !== '0') {
    params.categoryId = categoryId.value
  }
  getList(params);
}


const formRef = ref();
const modalTitle = ref("新增红包");
const modalVisible = ref(false);
const modalLoading = ref(false);
const formModal = ref({
  data: {
    type: null,
    title: "",
    description: "",
    url: "",
    img: "",
    appletId: "",
    status: "0",
    category: null,
    sort: 1,
    h5Type: '0',
    h5Url: ''
  },
  rules: {
    type: [{ required: true, message: "必填项", trigger: "blur" }],
  },
});
const handleOk = async () => {
  formRef.value
    .validateFields()
    .then(async () => {
      modalLoading.value = true;
      const { status, msg } = await edit({
        ...formModal.value.data,
        url: formModal.value.data.url ? encodeURIComponent(formModal.value.data.url) : '',
        h5Url: formModal.value.data.h5Url ? encodeURIComponent(formModal.value.data.h5Url) : ''
      });
      status == "200" ? message.success(msg) : message.warning(msg);
    })
    .finally(() => {
      handlerCancel();
    });
};
const handlerCancel = () => {
  modalLoading.value = false;
  modalVisible.value = false;
  formRef.value.resetFields();
  fileList.value = []
  fileData.value = []
  refresh();
};
const upOrDown = async (obj) => {
  const { type, title, description, url, img, appletId, logicId, status, category, sort } = obj;
  const { status: resultStatus, msg } = await edit({
    type,
    title,
    description,
    url,
    img,
    appletId,
    logicId,
    status: status == 0 ? 1 : 0,
    category,
    sort
  });
  resultStatus == "200" ? message.success(msg) : message.warning(msg);
  refresh();
};

const fileList = ref([]);
const fileData = ref({});
const handlerBeforeUpload = async (file) => {
  const { data } = await uploadFile(file.name);
  fileData.value = data;
};
const handlerFileChange = ({ file }) => {
  const { status, response } = file;
  if (status === "done") {
    formModal.value.data.img = `${urls.qiniu}${response.key}`;
    if (response.key) {
      fileList.value = [
        {
          name: response.key,
          response: { key: response.key },
          status: "done",
          url: `${urls.qiniu}${response.key}`,
          uid: "-1",
        },
      ];
    }
  }
};

const categoryId = ref('')
const categoryArr = ref([])
const getRedPacketTypes = async () => {
  const { data } = await getTypes({pageNum: 1, pageSize: 99})
  categoryArr.value = [{ name: '全部', logicId: '0' }, ...data.records]
  categoryId.value = '0'
}
const handleCategoryChange = ({ target }) => {
  categoryId.value = target.value
}
watch(categoryId, () => {
  pageNum.value = 1
  pageSize.value = PAGE_SIZE
  refresh()
})

onMounted(getRedPacketTypes)
</script>
<template>
  <div class="red-packet">
    <a-radio-group v-model:value="categoryId" button-style="solid" @change="handleCategoryChange">
      <template v-for="item in categoryArr" :key="item.logicId">
        <a-radio-button :value="item.logicId">{{ item.name }}</a-radio-button>
      </template>
    </a-radio-group>
    <a-button style="margin-bottom: 10px" type="primary" @click="handleAdd(null)">新增红包</a-button>
  </div>
  <a-table bordered rowKey="logicId" size="middle" :columns="columns" :data-source="source" :pagination="pagination"
    :loading="loading">
    <template #type="{ record }"> {{ NAME_MAP[record.type] }} </template>
    <template #status="{ record }">
      {{ record.status == 0 ? "下架" : "上架" }}
    </template>
    <template #op="{ record }">
      <a-button type="primary" size="small" @click="upOrDown(record)" style="margin-right: 10px">{{ record.status == 0 ?
          "上架" : "下架"
      }}</a-button>
      <a-button type="primary" size="small" @click="handleAdd(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal :title="modalTitle" v-model:visible="modalVisible" :confirm-loading="modalLoading" @ok="handleOk"
    @cancel="handlerCancel" width="500px">
    <a-form ref="formRef" :model="formModal.data" :rules="formModal.rules" :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }">
      <a-form-item label="红包类型" name="type">
        <a-select v-model:value="formModal.data.type" placeholder="请选择">
          <a-select-option v-for="type of TYPES" :key="type.value" :value="type.value">{{ type.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="红包标题" name="title">
        <a-input v-model:value="formModal.data.title" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="红包文案" name="description">
        <a-input v-model:value="formModal.data.description" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="红包口令" name="url">
        <a-input v-model:value="formModal.data.url" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="小程序id" name="appletId">
        <a-input v-model:value="formModal.data.appletId" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="图标" name="img">
        <a-upload v-model:fileList="fileList" name="file" accept="image/*" list-type="picture" :action="qiniuUrl"
          :data="fileData" :beforeUpload="handlerBeforeUpload" @change="handlerFileChange">
          <a-button> 上 传 </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="红包分类" name="category">
        <a-select v-model:value="formModal.data.category" placeholder="请选择">
          <template v-for="item in categoryArr.slice(1)" :key="item.logicId">
            <a-select-option :value="item.logicId">{{ item.name }}</a-select-option>
          </template>
        </a-select>
      </a-form-item>
      <a-form-item label="红包排序" name="sort">
        <a-input-number v-model:value="formModal.data.sort" :min="1" />
      </a-form-item>
      <a-form-item label="H5跳转方式" name="h5Type">
        <a-select v-model:value="formModal.data.h5Type" placeholder="请选择">
          <template v-for="item in H5_TYPES" :key="item.value">
            <a-select-option :value="item.value">{{ item.label }}</a-select-option>
          </template>
        </a-select>
      </a-form-item>
      <a-form-item label="H5链接" name="h5Url">
        <a-input v-model:value="formModal.data.h5Url" placeholder="请输入" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<style>
.red-packet {
  display: flex;
  justify-content: space-between;
}
</style>
