import { request, request2 } from "../utils/axios";
import { stringify } from "qs";

// 红包列表
export function list(params) {
  return request({
    url: `/wuan/admin/wx/moneyEnvelope/page?${stringify(params)}`,
    method: "get",
  });
}

// 新增/修改红包
export function edit(params) {
  return request({
    url: `/wuan/admin/wx/moneyEnvelope/saveOrUpdate?${stringify(params)}`,
    method: "post",
  });
}

// 红包分类
export function getTypes(params) {
  return request({
    url: `/wuan/admin/wx/moneyEnvelope/category/page?${stringify(params)}`,
    method: 'get'
  })
}
